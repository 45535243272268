import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'
import { Route, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from "react-toastify";  // ✅ Import Toastify
import "react-toastify/dist/ReactToastify.css";
import { debounce } from 'lodash';
import PopupModal from './PopupModel';
import AlertPanel from './AlertPanel';
import TrendingStocksSection from './TrendingStocks';
import ProfileMenu from './ProfileMenu';
import PriceIndicator from './PriceIndicator';
import AddStocksModal from './AddStocksModal';
import Sidebar from './Sidebar';
import { host } from '../../utilities/util';

interface Stock {
    tkr: string;
    atkr: string | null;
    inst: string;
    tk: number;
}
interface DhanStock {
    security_id: string;
    symbol_name: string;
    underlying_symbol: string;
    instrument_type?: string;
  }
  

export default function ProfessionalInterface() {
    const fetchDataCalled = useRef(false); // ✅ Prevent multiple calls
    const [showAddStocks, setShowAddStocks] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [isRunning, setIsRunning] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStocks, setSelectedStocks] = useState<DhanStock[]>([]);
    const [filteredStocks, setFilteredStocks] = useState<DhanStock[]>([]);

    const [addedStocks, setAddedStocks] = useState<DhanStock[]>([]);
    const [stocksData, setStocksData] = useState<DhanStock[]>([]);
    const [prices, setPrices] = useState([
        { label: 'Nifty 50', currentPrice: 17652.9, previousPrice: 17600.0 },
        { label: 'India VIX', currentPrice: 13.48, previousPrice: 14.5 },
        { label: 'US Dollar', currentPrice: 82.36, previousPrice: 82.50 },
        { label: 'BTC', currentPrice: 27890.12, previousPrice: 27700.12 },
        { label: 'GOLD', currentPrice: 89200, previousPrice: 87300 }
    ]);
    const [recentOrders, setRecentOrders] = useState([
        { id: 1, orderId: 'ORD12345', stock: 'AAPL', quantity: 10, orderType: 'Buy', time: '09:15 AM' },
        { id: 2, orderId: 'ORD12346', stock: 'GOOGL', quantity: 5, orderType: 'Sell', time: '09:32 AM' },
        { id: 3, orderId: 'ORD12347', stock: 'MSFT', quantity: 8, orderType: 'Buy', time: '09:45 AM' },
        { id: 4, orderId: 'ORD12348', stock: 'AMZN', quantity: 12, orderType: 'Sell', time: '10:01 AM' },
    ]);

    const [pendingOrders, setPendingOrders] = useState([
        { id: 5, orderId: 'ORD12349', stock: 'TSLA', quantity: 7, orderType: 'Buy', time: '10:14 AM' },
        { id: 6, orderId: 'ORD12350', stock: 'INFY', quantity: 15, orderType: 'Sell', time: '10:20 AM' },
    ]);

    const [failedOrders, setFailedOrders] = useState([
        { id: 7, orderId: 'ORD12351', stock: 'NFLX', quantity: 4, orderType: 'Buy', time: '10:28 AM' },
        { id: 8, orderId: 'ORD12352', stock: 'TCS', quantity: 9, orderType: 'Sell', time: '10:30 AM' },
    ]);
    const [loadingAddStocks, setLoadingAddStocks] = useState(false);
    const [loading, setLoading] = useState(true)

    const showToast = (message: string, type = "info") => {
        if (type === "success") toast.success(message);
        else if (type === "error") toast.error(message);
        else if (type === "warning") toast.warning(message);
        else toast.info(message);
    };

    const fetchFilteredStocks = async (query: string) => {
        if (!query.trim()) {
            setFilteredStocks([]);
            return;
        }

        try {
            const res = await fetch(`${host}search-instruments-dhan?q=${query}`);
            const data = await res.json();
            setFilteredStocks(data);
        } catch (err) {
            console.error("Search error:", err);
        }
    };

    const debouncedFetchStocks = useCallback(
        debounce((query: string) => {
            fetchFilteredStocks(query);
        }, 400),
        []
    );






    // Function to fetch only watchlist data
    const fetchData = useCallback(async () => {
        if (fetchDataCalled.current) return;
        fetchDataCalled.current = true;

        setLoading(true);
        showToast("Fetching watchlist...", "info");

        try {
            const response = await axios.get(`${host}get-watchlist-dhan_format`);
            const watchlist: DhanStock[] = response.data || [];

            setStocksData(watchlist);
            setAddedStocks(watchlist);

            console.log("Got Stocks Data",response)
            // console.log("Got Added Stock Data",addedStocks)
// tk is unique identifier
            showToast("Watchlist loaded successfully!", "success");
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error("❌ Axios error:", error.response?.data || error.message);
                showToast("Error fetching watchlist!", "error");
            } else {
                console.error("❌ Unexpected error:", error);
                showToast("Unexpected error occurred!", "error");
            }
        } finally {
            setLoading(false);
        }
    }, []);


    // Debounced version of fetchData to avoid multiple calls
    const debouncedFetchData = useCallback(debounce(fetchData, 500), []);

    // Fetch data on component mount
    useEffect(() => {
        debouncedFetchData();
    }, [debouncedFetchData]);



    // const filteredStocks = stocksData.filter(stock =>
    //     stock.tradingsymbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     stock.name.toLowerCase().includes(searchQuery.toLowerCase())
    // )

    const handleLogout = () => {
        console.log('Logging out...');
        // Perform your actual logout logic here (e.g., clearing auth tokens, redirect)
    };

    // Updated Add Stocks Handler
    const handleAddStocks = async () => {
        if (selectedStocks.length === 0) {
            showToast("No stocks selected!", "warning");
            return;
        }

        showToast("Adding selected stocks...", "info");

        try {
            const response = await axios.post(`${host}add-to-watchlist-dhan_format`, selectedStocks, {
                headers: { "Content-Type": "application/json" },
            });

            if (response.status === 201) {
                showToast("Stocks added successfully!", "success");
              
                // Merge new stocks into addedStocks without duplicates (based on security_id)
                setAddedStocks((prev) => {
                  const all = [...prev, ...selectedStocks];
                  const unique = all.filter(
                    (stock, index, self) =>
                      index === self.findIndex((s) => s.security_id === stock.security_id)
                  );
                  return unique;
                });
              
                // Clear UI state
                setSelectedStocks([]);
                setSearchQuery('');
                setShowAddStocks(false);
              } else {
                showToast("Unexpected response while adding stocks!", "warning");
              }
              
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error("❌ Axios error adding stocks:", error.response?.data || error.message);
                showToast("Error adding stocks!", "error");
            } else {
                console.error("❌ Unexpected error:", error);
                showToast("Something went wrong!", "error");
            }
        }
    };


    const removeStock = async (securityId: string) => {
        try {
          const response = await axios.delete(`${host}remove-from-watchlist`, {
            data: [securityId],
            headers: { "Content-Type": "application/json" },
          });
      
          if (response.status === 200) {
            console.log("✅ Stock removed:", response.data.message);
            setAddedStocks((prev) =>
              prev.filter((stock) => stock.security_id !== securityId)
            );
          } else {
            console.error("⚠️ Unexpected response:", response.data);
          }
        } catch (error: any) {
          console.error("❌ Error removing stock:", error.response?.data || error.message);
        }
      };
      
    const handleShowAddStocks = () => {
        setLoadingAddStocks(true); // Start loading

        // Simulate a small delay before opening the popup
        setTimeout(() => {
            setShowAddStocks(true);
            setLoadingAddStocks(false); // Stop loading once popup appears
        }, 1000); // 1 second delay for effect
    };
    // Handle search input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchQuery(value);
        debouncedFetchStocks(value); // debounced call
    };
    // Handle stock selection

    const handleStockSelection = (stock: DhanStock) => {
        setSelectedStocks((prev) => {
            const exists = prev.some((s) => s.security_id === stock.security_id);
            return exists
                ? prev.filter((s) => s.security_id !== stock.security_id)
                : [...prev, stock];
        });
    };
    



    const navigate = useNavigate();

    const [showAlertPanel, setShowAlertPanel] = useState(false);

    // Example alert data
    const allAlerts = [
        {
            id: 1,
            type: 'Order Executed',
            message: 'Order #12345 was executed.',
            timestamp: '2025-03-10 10:30:00',
        },
        {
            id: 2,
            type: 'Order Canceled',
            message: 'Order #54321 was canceled.',
            timestamp: '2025-03-10 11:00:00',
        },
        {
            id: 3,
            type: 'Filter Applied',
            message: 'Applied filter: "Nifty 50".',
            timestamp: '2025-03-10 11:05:00',
        },
    ];

    // Alert filtering
    const alertTypes = ['All', 'Order Executed', 'Order Canceled', 'Filter Applied'];
    const [selectedType, setSelectedType] = useState('All');

    const openAlert = () => setShowAlertPanel(true);
    const closeAlert = () => setShowAlertPanel(false);


    return (
        <div className="flex h-screen bg-gray-50">
            <Sidebar
                addedStocks={addedStocks}
                stocksData={stocksData}
                loadingAddStocks={loadingAddStocks}
                handleShowAddStocks={handleShowAddStocks}
                removeStock={removeStock}
                recentOrders={recentOrders}
                pendingOrders={pendingOrders}
                failedOrders={failedOrders}
                setShowSettings={setShowSettings}
            />

            {/* Main Content */}
            <div className="flex-1 ">
                <div className="h-full bg-white  shadow">
                    {/* <div className="px-6 py-4 border-b flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Dashboard</h2>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => { handleGenerateRequestToken() }}>Generate Request Token</button>
                    </div> */}
                    <div className="border-b px-6 py-3 flex items-center justify-between">
                        {/* Left side: Price Indicators */}
                        <div className="flex space-x-4 overflow-auto">
                            {prices.map((item) => {
                                const change = item.currentPrice - item.previousPrice;
                                const percentageChange = (change / item.previousPrice) * 100;
                                return (
                                    <PriceIndicator
                                        key={item.label}
                                        label={item.label}
                                        currentPrice={item.currentPrice}
                                        priceChange={change}
                                        percentageChange={percentageChange}
                                    />
                                );
                            })}
                        </div>

                        {/* Right side: Profile Icon + Logout Menu */}
                        <ProfileMenu onLogout={handleLogout} />
                    </div>
                    <div className="flex flex-wrap gap-2 ">
                        {/* Left: Trending Stocks Section */}
                        <div className="flex-1  max-w-[45%]">
                            <TrendingStocksSection />
                        </div>

                        {/* Right: Start/Stop Button Section */}
                        <div className="w-full md:w-[40%] max-w-sm  mt-6 px-6">
                            <div className="h-full flex items-start justify-center">
                                <div className="w-full px-4 py-6 bg-white border border-gray-200 rounded-lg shadow">
                                    <h3 className="text-md font-semibold text-gray-800 mb-4 text-center">Control Panel</h3>

                                    <div className="flex flex-col space-y-3">
                                        <button
                                            className={`w-full px-4 py-2 text-sm font-medium rounded-md transition ${isRunning
                                                ? 'bg-gray-300 cursor-not-allowed text-gray-700'
                                                : 'bg-blue-600 hover:bg-blue-700 text-white'
                                                }`}
                                            onClick={() => setIsRunning(true)}
                                            disabled={isRunning}
                                        >
                                            Start
                                        </button>

                                        <button
                                            className={`w-full px-4 py-2 text-sm font-medium rounded-md transition ${!isRunning
                                                ? 'bg-gray-300 cursor-not-allowed text-gray-700'
                                                : 'bg-red-600 hover:bg-red-700 text-white'
                                                }`}
                                            onClick={() => setIsRunning(false)}
                                            disabled={!isRunning}
                                        >
                                            Stop
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            {/* Right Side Action Buttons */}
            <div className="fixed right-16 bottom-16 flex space-x-4">
                <button
                    className="w-44 px-6 py-3 text-sm font-medium text-white rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition duration-300"
                    onClick={() => navigate('/logic-builder')}
                >
                    🔍 Logic Builder
                </button>

                <button
                    className="w-44 px-6 py-3 text-sm font-medium text-white rounded-lg shadow-lg bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 transition duration-300"
                    onClick={openAlert}
                >
                    🚨 Alert System
                </button>
            </div>

            {/* Alert Panel (compact) */}
            <AlertPanel
                show={showAlertPanel}
                onClose={closeAlert}
                alerts={allAlerts}
                alertTypes={alertTypes}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
            />


            {/* Add Stocks Popup */}
            <AddStocksModal
                show={showAddStocks}
                onClose={() => setShowAddStocks(false)}
                searchQuery={searchQuery}
                filteredStocks={filteredStocks}
                selectedStocks={selectedStocks}
                onSearchChange={handleInputChange}
                onToggleStock={handleStockSelection}
                onAddStocks={handleAddStocks}
            />

            {/* Settings Popup */}
            {showSettings && (
                <PopupModal
                    title="Settings"
                    onClose={() => setShowSettings(false)}
                >
                    <div className="space-y-4">
                        {/* Replace with actual settings content */}
                        <div className="text-sm text-gray-500">Settings content goes here.</div>
                    </div>
                </PopupModal>
            )}

            <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
        </div>
    )
}






// const handleGenerateRequestToken = async (url = "") => {
//     // Open the login URL in a new window
//     const token_url = await GenerateRequestTokenUrl()
//     console.log("TOKNE URL:", token_url)

//     if (!token_url) return;

//     const newWindow = window.open("http://localhost:3000/", "_blank", "width=600,height=400");
//     setWindowReference(newWindow);


//     window.addEventListener("message", (event) => {
//         try {
//             console.log("📩 Received message:", event);

//             // ✅ Ensure the message comes from the correct origin
//             if (event.origin !== "http://localhost:3000") return;

//             // Get the current URL from the event data
//             let currentURL = event.data.url;
//             console.log("🌍 Current URL:", currentURL);

//             console.log("🔴 Login not found initially, redirecting user...");
//             newWindow.location.href = token_url;

//             newWindow.postMessage({ action: "navigate" }, "http://localhost:3000"); // Ask the child to send its URL
//             setTimeout(() => {
//                 newWindow.location.href = "https://waliachandan.com/?action=login&type=login&status=success&request_token=jdb43RzZpEYgJXt4g4CBB4pOgH78Q7xA"
//             }, 5000)




//             if (currentURL.includes("request_token")) {
//                 console.log("🔄 request_token not detected, waiting for request token...");

//                 // Set an interval to keep checking the URL in the new window
//                 const checkRequestTokenComplete = setInterval(() => {
//                     try {
//                         newWindow.postMessage({ action: "request_token_check" }, "http://localhost:3000"); // Ask the child to send its URL
//                     } catch (err) {
//                         console.warn("⚠️ Cannot send message to new window.");
//                         clearInterval(checkRequestTokenComplete);
//                     }
//                 }, 3000); // Check every 2 seconds
//             }

//             // ✅ If "login" is in the URL, start monitoring until it disappears
//             if (currentURL.includes("login")) {
//                 console.log("🔄 Login detected, waiting for completion...");

//                 // Set an interval to keep checking the URL in the new window
//                 const checkLoginComplete = setInterval(() => {
//                     try {
//                         newWindow.postMessage({ action: "check_url" }, "http://localhost:3000"); // Ask the child to send its URL
//                     } catch (err) {
//                         console.warn("⚠️ Cannot send message to new window.");
//                         clearInterval(checkLoginComplete);
//                     }
//                 }, 2000); // Check every 2 seconds
//             }
//             // else {
//             //     console.log("🔴 Login not found initially, redirecting user...");
//             //     newWindow.location.href = token_url;

//             //     newWindow.postMessage({ action: "navigate" }, "http://localhost:3000"); // Ask the child to send its URL
//             //     setTimeout(() => {
//             //         newWindow.location.href = "https://waliachandan.com/?action=login&type=login&status=success&request_token=jdb43RzZpEYgJXt4g4CBB4pOgH78Q7xA"
//             //     }, 5000)

//             // // ✅ Monitor when "request_token" appears in the URL
//             // const checkRequestToken = setInterval(() => {
//             //     try {
//             //         let updatedURL = newWindow.location.href;
//             //         console.log("🔍 Checking updated URL:", updatedURL);

//             //         // Extract request_token from the URL
//             //         const urlParams = new URL(updatedURL).searchParams;
//             //         const requestToken = urlParams.get("request_token");

//             //         if (requestToken) {
//             //             console.log("✅ Request Token Found:", requestToken);
//             //             setRequestToken(requestToken)
//             //             // Send token to parent window (optional)
//             //             window.opener.postMessage({ request_token: requestToken }, "http://localhost:3000");

//             //             // Close the window after 2 seconds
//             //             setTimeout(() => {
//             //                 newWindow.close();
//             //                 console.log("🛑 Window closed after retrieving token.");
//             //             }, 2000);

//             //             clearInterval(checkRequestToken); // Stop checking
//             //         }
//             //     } catch (err) {
//             //         console.warn("⚠️ Cannot access window location due to cross-origin policy.");
//             //         clearInterval(checkRequestToken);
//             //     }
//             // }, 2000); // Check every 2 seconds
//             // }
//         } catch (error) {
//             console.warn("⚠️ Error processing message:", error);
//         }
//     }, false);
// };
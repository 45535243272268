import React from "react";
import { Loader, Plus, Settings, X } from "lucide-react";

interface DhanStock {
  security_id: string;
  symbol_name: string;
  underlying_symbol: string;
  instrument_type?: string;
}

interface Order {
  id: number;
  orderId: string;
  stock: string;
  quantity: number;
  time: string;
  orderType: string;
}

interface SidebarProps {
  addedStocks: DhanStock[];
  stocksData: DhanStock[];
  loadingAddStocks: boolean;
  handleShowAddStocks: () => void;
  removeStock: (securityId: string) => void;
  recentOrders: Order[];
  pendingOrders: Order[];
  failedOrders: Order[];
  setShowSettings: (val: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  addedStocks,
  stocksData,
  loadingAddStocks,
  handleShowAddStocks,
  removeStock,
  recentOrders,
  pendingOrders,
  failedOrders,
  setShowSettings,
}) => {
  return (
    <div className="w-64 bg-white border-r">
      <nav className="p-3 space-y-4">

        {/* ➕ Add Stocks Button */}
        <section>
          <button
            className="w-full flex items-center px-4 py-2 text-sm font-medium rounded-md hover:bg-gray-100 mt-4"
            onClick={handleShowAddStocks}
            disabled={loadingAddStocks}
          >
            {loadingAddStocks ? (
              <span className="flex items-center">
                <Loader className="mr-2 h-4 w-4 animate-spin" /> Loading...
              </span>
            ) : (
              <span className="flex items-center">
                <Plus className="mr-2 h-4 w-4" /> Add Stocks -{" "}
                <span className="text-gray-500">Selected ({addedStocks.length})</span>
              </span>
            )}
          </button>

          {/* Watchlist Stocks List */}
          <div className="mt-2 max-h-64 overflow-y-auto">
            <div className="space-y-1">
              {addedStocks.length > 0 ? (
                addedStocks.map((stock) => (
                  <div
                    key={stock.security_id}
                    className="flex items-center justify-between p-1 rounded-md hover:bg-gray-50 text-xs"
                  >
                    <div className="leading-tight">
                      <div className="font-medium">{stock.symbol_name}</div>
                      <div className="text-gray-500">{stock.underlying_symbol}</div>
                    </div>
                    <button
                      className="p-0.5 rounded-full hover:bg-gray-200"
                      onClick={() => removeStock(stock.security_id)}
                    >
                      <X className="h-3 w-3 text-gray-500" />
                    </button>
                  </div>
                ))
              ) : (
                <div className="p-2 text-sm text-gray-400">No stocks added yet</div>
              )}
            </div>
          </div>
        </section>

        <div className="border-t my-2"></div>

        {/* 🕒 Orders */}
        <OrderSection title="Recent Orders" orders={recentOrders} colorClass="text-gray-600" />
        <OrderSection title="Pending Orders" orders={pendingOrders} colorClass="text-yellow-600" />
        <OrderSection title="Failed Orders" orders={failedOrders} colorClass="text-red-600" />

        <div className="border-t my-2"></div>

        {/* ⚙️ Settings */}
        <section>
          <button
            className="w-full flex items-center px-4 py-2 text-sm font-medium rounded-md hover:bg-gray-100"
            onClick={() => setShowSettings(true)}
          >
            <Settings className="mr-2 h-4 w-4" />
            Settings
          </button>
        </section>
      </nav>
    </div>
  );
};

// 📦 Reusable OrderSection component
const OrderSection: React.FC<{
  title: string;
  orders: Order[];
  colorClass: string;
}> = ({ title, orders, colorClass }) => (
  <section className="space-y-4 text-[11px] text-gray-800 font-medium">
    <div className="text-gray-500 font-semibold mb-1 uppercase">{title}</div>
    <div className="max-h-32 overflow-y-auto divide-y divide-gray-200 pr-1">
      {orders.length > 0 ? (
        orders.map((order) => (
          <div key={order.id} className="py-1.5">
            <div className="flex justify-between items-center">
              <span className={colorClass}>{order.time}</span>
              <span
                className={`w-5 h-5 rounded-full text-[10px] font-bold flex items-center justify-center ${
                  order.orderType === "Buy"
                    ? "bg-green-100 text-green-700"
                    : "bg-red-100 text-red-600"
                }`}
              >
                {order.orderType === "Buy" ? "B" : "S"}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-900">{order.stock}</span>
              <span className={colorClass}>{order.quantity} Shares</span>
            </div>
          </div>
        ))
      ) : (
        <div className="text-gray-400 italic py-2">No {title.toLowerCase()}</div>
      )}
    </div>
  </section>
);

export default Sidebar;
